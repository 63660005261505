import { NATIVE } from '@sushiswap/core-sdk'
import useMenu from 'app/components/Header/useMenu'
import LanguageSwitch from 'app/components/LanguageSwitch'
import Web3Network from 'app/components/Web3Network'
import Web3Status from 'app/components/Web3Status'
import useIsCoinbaseWallet from 'app/hooks/useIsCoinbaseWallet'
import { useActiveWeb3React } from 'app/services/web3'
import { useETHBalances } from 'app/state/wallet/hooks'
import Image from 'next/image'
import Link from 'next/link'
import React, { FC, Fragment, useState } from 'react'

import { NavigationItem } from './NavigationItem'

const Mobile: FC = () => {
  const menu = useMenu()
  const { account, chainId, library } = useActiveWeb3React()
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const [open, setOpen] = useState(false)
  const isCoinbaseWallet = useIsCoinbaseWallet()

  return (
    <>
      <header className="w-full flex items-center justify-between min-h-[64px] h-[64px] px-4">
        <div className="flex justify-between flex-grow">
          <div className="p-2 rounded-full hover:bg-white/10">
            <div className="fixed inset-y-0 left-0 pr-10 max-w-[300px] flex">
              <div className="w-screen max-w-sm">
                <div className="fullNav h-full flex flex-col py-6 bg-[#171616] shadow-xl overflow-x-hidden">
                  <nav className="navbar flex-1 pl-6 justify-content-center bg-[#171616]" aria-label="Sidebar">
                    <div className="logo">
                      <Link href="/swap" passHref={true}>
                        <Image src="/images/logo-light.png" alt="Swapped logo" width="164.26px" height="34px" />
                      </Link>
                    </div>
                    {menu.map((node) => {
                      return <NavigationItem node={node} key={node.key} />
                    })}
                  </nav>

                  <div className="hidden lg:flex z-index-[-1]">
                    <LanguageSwitch />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="flex flex-col gap-4 px-6">
              <div
                style={{
                  background:
                    'linear-gradient(#171616 0 0) padding-box,linear-gradient(135deg, #f9560a 0%, #ffb800 18%, #52a414 36%, #00aabd 54%, #224e8f 72%, #722378 89%, #c4074d 100%) border-box',
                  border: '2px solid transparent',
                  borderRadius: '10px',
                }}
                className="flex items-center justify-start gap-2"
              >
                <div className="flex items-center w-auto text-sm font-bold border-2 rounded shadow cursor-pointer pointer-events-auto select-none border-dark-800 hover:border-dark-700 bg-dark-900 whitespace-nowrap">
                  {library && (library.provider.isMetaMask || isCoinbaseWallet) && (
                    <div className="">
                      <Web3Network />
                    </div>
                  )}
                  {account && chainId && userEthBalance && (
                    <Link
                      href={{
                        pathname: '/portfolio/[account]',
                        query: { account: account },
                      }}
                      passHref={true}
                    >
                      <a className="hidden px-3 text-high-emphesis text-bold md:block">
                        {/*@ts-ignore*/}
                        {userEthBalance?.toFixed(2)} {NATIVE[chainId || 1].symbol}
                      </a>
                    </Link>
                  )}
                  <Web3Status />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Mobile
